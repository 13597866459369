.checkbox {
    padding-left: 20px;
    margin: 20px 0px;
    label {
      display: inline-block;
      position: relative;
      padding-left: 5px;
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 17px;
        height: 17px;
        left: 0;
        margin-left: -20px;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-color: #fff;
        -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      }
      &::after {
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
        margin-left: -20px;
        padding-left: 3px;
        padding-top: 1px;
        font-size: 11px;
        color: #555555;
      }
    }
    input[type="checkbox"] {
      opacity: 0;
      &:focus + label::before {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }
      &:checked + label::after {
        font-family: 'FontAwesome';
        content: "\f00c";
      }
      &:disabled + label {
        opacity: 0.65;
        &::before {
          background-color: #eeeeee;
          cursor: not-allowed;
        }
      }
    }
    &.checkbox-circle label::before {
      border-radius: 50%;
    }
    &.checkbox-inline {
      margin-top: 0;
    }
  }

  .checkbox-primary input[type="checkbox"]:checked + label{
    &::before { 
        background-color: #00a69c;
        border-color: #008880;
    }
  }
  .checkbox-primary input[type="checkbox"]:checked + label {
    &::after {
        color: #fff; 
    }
  }
  