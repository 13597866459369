// - - - - - - - - - - - - - - - - - - -
// - - components

// - - global components styles
.nav-tabbing {
    width: 100%;
    max-width: 650px;
    text-align: center;
    margin: 0 auto;
    margin-top: -90px;

    &__item {
        background: #fff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
        border: solid 1px #dcdcdc;
        border-radius: 5px;
        transition: all 0.3s;

        &:hover {
            box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.12);
            transition: all 0.3s;

            span {
                opacity: 1 !important;
                transition: all 0.3s;

            }
        }

        a {
            display: block;
            position: relative;
            padding: 20px;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }

            &:active {
                text-decoration: none;
            }

            &:visited {
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }
        }

        h5 {
            color: #00a69c;
            font-size: 16px;
            margin-top: -20px;
        }

        span {
            display: block;
            width: 50px;
            height: 4px;
            background: #00a69c;
            border-radius: 25px;
            position: absolute;
            bottom: -3px;
            left: 0;
            right: 0;
            margin: auto;
            opacity: 0;
            transition: all 0.3s;
        }

        img {
            width: 100%;
            max-width: 123px;
        }
    }
}

.tab-act {
    opacity: 1 !important;
    transition: all 0.3s;
}

.hero-header-faq {
    display: block;
    height: 400px;
    background: url(../images/img-hero.jpg);
    background-size: cover !important;
    position: relative;
}

//input
form {
    .success-alert {
        color: #14b876;
        font-size: 13px;
        text-align: center;
        display: inline-block;
        width: 100%;
        margin: 20px 0px;
    }
    .form-group {
        margin-bottom: 20px;
        position: relative;
        p {
            display: block;
            margin-bottom: 10px;
            span {
                color: #00a69c;
            }
        }

        .error-input {
            border: solid 1px #e5361a !important;
        }

        .error-alert {
            display: inline-block;
            margin: 10px 0px;
            color: #e5361a;
            font-size: 13px;
        }

        
                

        .see-pass {
            position: absolute;
            top: 44px;
            right: 20px;
            cursor: pointer;
        }

        input.hrs-input {
            box-sizing: border-box;
            padding: 15px 20px;
            width: 100%;
            border: solid 1px #e6e6e6;
            border-radius: 3px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #e5e5e5;
            }
            &::-moz-placeholder { /* Firefox 19+ */
            color: #e5e5e5;
            }
        }

        textarea.medium-h {
            min-height: 100px;
        }

        textarea {
            box-sizing: border-box;
            padding: 15px 20px;
            width: 100%;
            min-height: 200px;
            border: solid 1px #e6e6e6;
            border-radius: 3px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #e5e5e5;
            }
            &::-moz-placeholder { /* Firefox 19+ */
            color: #e5e5e5;
            }
        }
    }
    button {
        padding: 15px 50px;
        border: none;
        background: #00a69c;
        color: #fff;
        font-weight: 800;
        border-radius: 25px;
        box-shadow: 0px 8px 15px rgba(0,166,156,0.20);
        transition: all 0.3s;
        &:hover {
            box-shadow: 0px 15px 25px rgba(0,166,156,0.20);
        }
    }
    

}

.attch-file {
    position: relative;
    border: solid 1px #dcdcdc;
    box-shadow: 0px 5px 10px rgba(51, 51, 51, 0.07);
    border-radius: 4px;
    cursor: pointer;
    input {
        opacity: 0;
        position: absolute;
    }
    p {
        padding: 20px;
        margin: 0px;
        display: block;
        text-align: center;
        cursor: pointer;
    }
    span {
        img {
            margin-right: 20px;
        }
    }
}


.hearsay-input {
    padding: 15px;
    font-size: 14px;
    border: solid 1px rgb(55, 185, 178);
    box-sizing: border-box;
    width: 100%;
    border-radius: 3px;
}

.search {
    padding: 20px;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 185px;
    h4 {
        color: #fff;
    }
    p {
        color: #fff;
    }
}

/*loading-screen*/
.loading-screen {
    position: fixed;
    z-index: 1080;
    background: #fff;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        max-width: 110px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
    }
}