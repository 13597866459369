.form-hearsay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 350px;
    height: 525px;

    &__logo {
        margin-bottom: 60px;
        img {
            width: 100%;
            max-width: 180px;
        }
    }

    &__title {
        margin-bottom: 30px;
        p {
            font-size: 12px;
            color: #98a0b3;
        }
    }
}



span#uploadFilesZone {
    display: block;
    width: 100%;
    border: solid 1px #efefef;
    text-align: center;
}

.dropzone .dz-message {
    text-align: center;
    margin: 0px;
    background: #fff;
    padding: 20px;
    height: 59px;
    input {
        opacity: 0;
    }

    img {
        margin-right: 10px;
    }
}