
.card-item {
    padding: 20px;
    &__inner {
        padding: 40px;
        background: #ecf8f7;
        border-radius: 5px; 
        transition: all 0.3s;
    }

    &__content {
        h4 {
            margin-bottom: 40px;
            transition: all 0.3s;
        }

        h1 {
            color: #00a69c;
            transition: all 0.3s;
        }

        p {
            margin-bottom: 20px;
            transition: all 0.3s;
        }
    }
}

.center {
    .card-item {
        &__inner {
            padding: 40px;
            background: #00a69c;
            border-radius: 5px;
            box-shadow: 0px 20px 45px rgba(0, 166, 156, 0.32);
            transition: all 0.3s;
            transform: scale(1.1);
        }

        &__content {
            h4 {
                margin-bottom: 40px;
                color: #fff;
                transition: all 0.3s;
            }
    
            h1 {
                color: #00a69c;
                color: #fff;
                transition: all 0.3s;
            }
    
            p {
                margin-bottom: 20px;
                color: #fff;
                transition: all 0.3s;
            }

            h5 {
                color: #fff;
                transition: all 0.3s;
            }

            .line-green {
                background: #ffffff !important;
            }
        }
        
    }
}