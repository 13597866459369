// - - - - - - - - - - - - - - - - - - -
// - - pages

// - - home styles - a unique styles that implemented in a specified pages

.bg-block {
    background: url(../images/bg-hero@2x.png) no-repeat;
    background-size: 100%; 
}

.cover-pages {
    padding-top:70px; 
    height: 100vh;
    width: 100%;

    &__inneer {
        margin-top: 90px;
    }

    &__content {
        margin-top: 80px;

        h1 {
            margin-bottom: 20px;
            color: #00a69c;
        }

        p {
            font-size: 22px;
            margin-bottom: 40px;
            color: #587976;
        }

        img {
            width: 100%;
            min-width: 625px;
        }
        
    }
}


.grid-feature {
    margin: 80px 0px;
    img {
        width: 100%;
        max-width: 123px;
        margin-bottom: 20px;
    }
    p {
        font-size: 18px;
    }
    
}


.list-feature {
    margin: 80px 0px 120px;
    &__item {
        margin: 180px 0px 0px;
        img {
            width: 100%;
            max-width: 364px;
            margin-top: 60px;
        }
    }
    &__desc {
        position: relative;
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
    }
    .bf-number {
        text-align: center;
        position: absolute;
        width: 100%;
        top: -60px;
        z-index: 1;
        h1 {
            font-size: 300px;
            margin-top: 0px;
            color: #f7f7f7;
        }
        .shadow-white {
            display: block;
            width: 100%;
            height: 180px;
            position: absolute;
            bottom: 52px;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        }
    }
    .front-box {
        position: relative;
        z-index: 2;
        padding-top: 120px;

        p {
            line-height: 24px;
        }
    }
}

.subscribe {
    background: url(../images/img-subscribe.png) no-repeat;
    background-size: 100%; 
    padding: 120px 0px;
}

.subscribe {
    
    position: relative;
    z-index: 2;
    &__form {
        
        margin-top: -20px;
        button {
            padding: 15px 50px;
            border: none;
            background: #00a69c;
            color: #fff;
            font-weight: 800;
            border-radius: 25px;
            margin-left: -56px;
            box-shadow: 0px 15px 26px rgba(0, 166, 156, 0.45);
        }
    
    
        input {
            border: none;
            box-sizing: border-box;
            padding: 15px 25px;
            border-radius: 25px;
            width: 100%;
            max-width: 300px;
            background: #ecf8f7;
        }
    
        p {
            margin-top: 10px;
            font-size: 12px;
        }
        
    }
}


.list-client {
    &__inner {
        a {
            display: inline-block;
            padding: 0px;
            width: 100%;
            max-width: 289px;
            border-radius: 10px;
            overflow: hidden;
            margin: 10px;
            box-shadow: 0px 14px 64px #0000000f;
            &:hover {
                img {  
                    filter: grayscale(0);
                    opacity: 1;
                    transition: all 0.3s;
                }
            }

            img {
                width: 100%;
                width: 100%;
                filter: grayscale(1);
                opacity: 0.5;
                transition: all 0.3s;
            }
        }
    }
}

.right-assets {
    position: absolute;
    right: 0;
    top: 250vh;
}

.left-assets {
    position: absolute;
    left: 0;
    top: 500vh;
}