/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "bootstrap/variables.scss";
@import "bootstrap/mixins.scss";

// Reset and dependencies
@import "bootstrap/normalize.scss";
@import "bootstrap/print.scss";
@import "bootstrap/glyphicons.scss";

// Core CSS
@import "bootstrap/scaffolding.scss";
@import "bootstrap/type.scss";
@import "bootstrap/code.scss";
@import "bootstrap/grid.scss";
@import "bootstrap/tables.scss";
@import "bootstrap/forms.scss";
@import "bootstrap/buttons.scss";

// Components
@import "bootstrap/component-animations.scss";
@import "bootstrap/dropdowns.scss";
@import "bootstrap/button-groups.scss";
@import "bootstrap/input-groups.scss";
@import "bootstrap/navs.scss";
@import "bootstrap/navbar.scss";
@import "bootstrap/breadcrumbs.scss";
@import "bootstrap/pagination.scss";
@import "bootstrap/pager.scss";
@import "bootstrap/labels.scss";
@import "bootstrap/badges.scss";
@import "bootstrap/jumbotron.scss";
@import "bootstrap/thumbnails.scss";
@import "bootstrap/alerts.scss";
@import "bootstrap/progress-bars.scss";
@import "bootstrap/media.scss";
@import "bootstrap/list-group.scss";
@import "bootstrap/panels.scss";
@import "bootstrap/responsive-embed.scss";
@import "bootstrap/wells.scss";
@import "bootstrap/close.scss";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";
