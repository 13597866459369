// - - - - - - - - - - - - - - - - - - -
// - - base

// - - project defaults for base elements - h1-h6, p, a, etc.

a, p, span {
    color: #3c5a64;
}

h1, h2, h3, h4, h5, h6 {
    color: #334247;
    font-weight: 900 !important;
}

body {
    font-family: $font-primary !important;
    font-size: 16px;
    overflow-x: hidden;
}

.wraper {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
}

.section-page {
    padding: 120px 0px;
}

.header-section {
    margin-bottom: 40px;
    p {
        font-size: 18px;
    }
    h3 {
        font-weight: 900;
    }
    
}

.line-green {
    display: inline-block;
    width: 20px;
    height: 5px;
    background: #00a69c;
    margin-bottom: 20px;
    margin-top: 10px;
    border-radius: 50px !important;
}



.footers {
    background: #f7fdfd;
    padding: 245px 0px 60px;
    margin-top: -275px;
    position: relative;
    z-index: 1;

    &__inner {
        a {
            font-size: 14px;
            display: block;
            margin-bottom: 10px;
            color: #7c949e;
        }

        h4 {
            margin-bottom: 20px;
        }

        img.footer-logo {
            width: 100%;
            max-width: 112px;
            margin-bottom: 20px;
        }

        p.smaller {
            font-size: 12px;
        }

        a.sosmed-footer {
            display: inline-block;
            margin-right: 10px;
        }
    }
}



.static-ftr {
    margin-top: 0px !important;
    padding-top: 80px !important;
}

.heading-left {
    margin-bottom: 20px;
}

.push-top {
    padding-top: 250px;
}

a {
    &:hover {
        text-decoration: none;
    }
    &:focus {
        text-decoration: none;
    }
    &:active {
        text-decoration: none;
    }
    &:visited {
        text-decoration: none;
    }
}

.pale-bg {
    background: #f4f6f8;
}
.wrap-tops{
    padding-top: 7em;
    text-align: center;
    color: #fff;
}
.wrap-text{
    height: 72px;
    font-size: 14px;
    padding-top: 15px;
}
.legal-page{
    margin: 5% 0;
    color: #334247 !important;
}
.legal-page-active{
    font-weight: 600;
    margin: 5% 0;
}
.color_a{
    color: #334247 !important;
}
.border-left-legal{
    color: #0d536e;
    border-left: solid 1px #eeeeee !important;
    padding-left: 3em;
    @media only screen 
    and (min-device-width : 320px)
    and (max-device-width : 480px) {
        border-left: none !important;
        padding: 1em;
    }
}
.qnock-legal{
    color: #0d536e;
    text-align: center;
    margin-top: 2em;
}
.text-qnock-legal{
    text-align: center;
    color: #60909f;
}