.btn-primary {
    font-size: 14px;
    display: inline-block;
    padding: 10px 30px;
    background: #00a69c;
    box-shadow: 0px 10px 20px rgba(0, 166, 156, 0.22);
    transition: all 0.3s;

    &:hover {
        box-shadow: 0px 20px 40px rgba(0, 166, 156, 0.42);
        background: #00a69c;
        text-decoration: none;
        transition: all 0.3s;
    }
}

.btn-light {
    font-size: 14px;
    display: inline-block;
    padding: 10px 30px;
    color: #00a69c;
    transition: all 0.3s;
    background: #fff;

    &:hover {
        box-shadow: 0px 10px 20px rgba(0, 166, 156, 0.10);
        background: #fff;
        text-decoration: none;
        transition: all 0.3s;
    }
}

.btn-rounded {
    border-radius: 25px; 
}

a.txt-link {
    display: inline-block;
    color: #345995;
    text-decoration: none;
    font-size: 12px;
    margin: 20px 0px;
    font-weight: bold;
}

.btn-hearsay-box {
    display: inline-block;
    width: 100%;
    border-radius: 4px;
}

.underline-link {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #5a5b5d;
    font-size: 14px;
    text-decoration: underline;
    margin: 20px 0px;
}

.download-btn-bar {
    border: solid 1px #cecece;
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.03);
    cursor: pointer;
    color: #333;
    display: block;
    width: 100%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    a {
        position: absolute;
        right: 20px;
        font-weight: bold;
        color: #00a69c;
        font-size: 16px;
        top: 18px;
        text-decoration: none !important;
    }
}