// - - - - - - - - - - - - - - - - - - -
// - - Navbar

.navbar-hearsay {
    background: transparent;
    border: none;
    padding: 20px;
    transition: all 0.3s;

    .container-fluid {
        width: 100%;
        max-width: 1000px;

        a.navbar-brand {
            padding: 0px;
            img {
                width: 100%;
                max-width: 130px;
            }
        }

        ul.navbar-nav {
            li {
                a {
                    display: inline-block;
                    color: #587976;
                    font-weight: 700;
                    font-size: 14px;
                    padding: 15px 20px;
                    transition: all 0.3s;

                    &:hover {
                        text-decoration: none;
                        background-color: transparent;
                        color: #00a69c;
                        transition: all 0.3s;
                    }

                    &:focus {
                        text-decoration: none;
                        background-color: transparent;
                        color: #00a69c;
                        transition: all 0.3s;
                    }
                }

                .rounded-menu {
                    background: #f4ffff;
                    border-radius: 25px;
                    padding: 10px 25px;
                    box-shadow: 0px 10px 20px rgba(87, 128, 128, 0.07);
                    transition: all 0.3s;
                    margin-left: 40px;
                    margin-top: 5px;

                    &:hover {
                        box-shadow: 0px 20px 40px rgba(87, 128, 128, 0.14);
                        transition: all 0.3s;
                        background: #00a69c;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.bg-nav {
    background: #ffffff;
    box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.07);
    padding: 10px;
    transition: all 0.3s;

    #dark-logo {
        display: block !important;
    }

    #light-logo {
        display: none !important;
    }

    a {
        color: #00a69c !important;
    }

    .rounded-menu {
        background: #00a69c !important;
        color: #fff !important;

    }
}

.invert-nav {
    #dark-logo {
        display: none;
    }

    #light-logo {
        display: block;
    }
}

#light-logo {
    display: none;
}

