// - - - - - - - - - - - - - - - - - - -
// - - faq

.contact-us {
    display: none;
    padding: 40px 0px;
    position: relative;
    top: 0;
    width: 100%;
}

.download-help {
    display: none;
    padding: 40px 0px;
    position: relative;
    top: 0;
    width: 100%;
}

.faq {
    padding: 40px 0px;
    position: relative;
    top: 0;
    width: 100%;

    &__item {
        margin-bottom: 20px;
    }

    &__group {
        margin-bottom: 60px;
    }

    &__bar {
        border: solid 1px #cecece;
        padding: 20px;
        border-radius: 3px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
        cursor: pointer;
        color: #333;
        display: block;
        width: 100%;
        transition: all 0.3s;

        span {
            position: absolute;
            right: 20px;
        }

        &:hover {
            text-decoration: none;
            color: #333;
            box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.07);
            transition: all 0.3s;
        }
    }

    &__detail {
        position: absolute;
        top: 0;
        background: #fff;
        z-index: 100;
        padding: 20px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
        margin: 0px -15px;
    }

    &__content {
        padding: 20px;
        border: solid 1px rgb(222, 222, 222);
        margin-top: -1px;
        li {
            color: #00a69c;
        }

        p {
            color: #3c5a64;
            line-height: 26px;
            font-size: 14px;
        }
    }

    .res-serc {
        display: none;
    }
    
    
    
}



.show-detail {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
}

.faq__nav {
    margin: 20px 0px;
    a {
        color: #00a69c;
        font-size: 16px;
        &:hover {
            text-decoration: none;
        }
        &:focus {
            text-decoration: none;
        }
        &:active {
            text-decoration: none;
        }
        &:visited {
            text-decoration: none;
        }
    }
    img {
        vertical-align: initial;
        margin-right: 10px;
    }
}

.srch-margin {
    margin-bottom: 10px !important;
}

