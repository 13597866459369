@media only screen and (max-width: 720px) {
    .form-hearsay {
        position: relative !important;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 350px;
        height: 100% !important;
        padding: 60px 0px !important;
    }

    .cover-pages {
        height: 100% !important;

        &__inneer {
            margin-top: 90px;
            padding: 0px 20px;
        }
    }

    .navbar-nav {
        li {
            a {
                color: #587976 !important;
            }
        }
    }

    .rounded-menu {
        color: #fff !important;
        background: #d2eceb !important;
        box-shadow: 0px !important;
    }

    .push-top {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    #light-logo {
        display: none !important;
    }

    #dark-logo {
        display: block !important;
    }

    .nav-tabbing {
        &__item {
            margin-bottom: 20px;
        }
    }

    .navbar-hearsay {
        background: #fff;
    }

    .section-page {
        padding: 120px 15px 0px;
    }

    .list-feature {
        &__item {
            margin: 40px 0px 80px;
        }
    }

    .bg-block {
        background: #fff;
        background-size: 100%;
    }

    .owl-nav {
        display: block !important;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 34px;
    }

    .header-section p {
        font-size: 16px;
    }

    .footers {
        background: #f7fdfd;
        padding: 60px 0px 60px;
        margin-top: 0px;
        position: relative;
        z-index: 1;
    }

    .subscribe {
        background: #fff;
        background-size: 100%;
        padding: 120px 0px;

        &__form {
            button {
                padding: 15px 50px;
                border: none;
                background: #00a69c;
                color: #fff;
                font-weight: 800;
                border-radius: 25px;
                margin-left: 0px;
                box-shadow: 0px 15px 26px rgba(0,166,156,0.45);
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 14px;
            }
        }
    }

    .rounded-menu {
        margin-left: 20px !important;
    }

    
    .navbar-toggle .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background: #00a69c;
    }

    div#bs-example-navbar-collapse-1 {
        padding-bottom: 20px;
        margin: 20px -25px 0px !important;
    }

    .right-assets {
        position: absolute;
        right: 0;
        top: 250vh;
        display: none;
    }
    
    .left-assets {
        position: absolute;
        left: 0;
        top: 500vh;
        display: none;
    }

    
    
}

